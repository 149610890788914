import Link from 'next/link'
import { memo } from 'react'

import CustomImage from '@/components/elements/images/image'

const READ_MORE_LABEL = 'Read More'

const InitiativeCard = ({ id, image, name, description, link }) => (
  <div key={id} className="initiative-card">
    {image.data && (
      <div className="initiative-image-container">
        <CustomImage
          className="initiative-image"
          media={image.data.attributes}
          layout="fill"
          objectFit="contain"
          shimmer
        />
      </div>
    )}
    {name && (
      <div className="initiative-title">
        <p>{name}</p>
      </div>
    )}
    {description && (
      <div className="initiative-description">
        <p>{description}</p>
      </div>
    )}
    {link && link[0] === '/' ? (
      <Link href={link} target="_self">
        <div className="initiative-link">
          <p>{READ_MORE_LABEL}</p>
        </div>
      </Link>
    ) : (
      <a href={link} target="_blank" rel="noreferrer" className="initiative-link">
        <div>
          <p>{READ_MORE_LABEL}</p>
        </div>
      </a>
    )}
  </div>
)

export default memo(InitiativeCard)
