import { memo } from 'react'

import InitiativeCard from '@/components/elements/cards/initiativeCard'
import HeaderOverhaul from '@/components/elements/headerOverhaul'

const Initiatives = ({ data }) => {
  const { header, initiatives } = data
  return (
    <div className="initiatives-wrapper">
      <div className="initiatives-content">
        <HeaderOverhaul header={header} subtitle />
        <div className="initiatives-grid grid-wrapper grid-columns-3-1">
          {initiatives.map(({ id, name, description, link, image }) => (
            <InitiativeCard
              key={id}
              id={id}
              name={name}
              description={description}
              link={link}
              image={image}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default memo(Initiatives)
